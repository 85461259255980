<template>
  <div class="verification">
    <!--    右侧头部区域-->
    <div class="login-right-top">
      <span>绑定手机号</span>
      <!-- <router-link to="normal">返回密码登录</router-link> -->
    </div>
    <!--    右侧表单区域-->
    <div class="login-form">
      <el-form
        ref="verLoginFormRef"
        :model="verLoginForm"
        :rules="verLoginFormRules"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="verLoginForm.phone"
            placeholder="请输入手机号码"
            maxlength="11"
            @keyup.native="restempty('verLoginForm', 'phone')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="verLoginForm.code"
            placeholder="请输入4位验证码"
            maxlength="5"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          ></el-input>
          <el-button
            plain
            class="get-verfi"
            @click="getVerfiCode"
            :disabled="disabled"
            >{{ button_label }}</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="submit-btn" @click="goBindWX"
            >立即绑定</el-button
          >
        </el-form-item>
        <el-form-item id="checkbox-form">
          <!-- <router-link to="register"
            ><span class="register">免费注册></span></router-link
          > -->
        </el-form-item>
      </el-form>
    </div>
    <!--    右侧表单尾部区域-->
    <!-- <div class="login-right-footer">
      <div class="left">
        <span>其他登录方式</span>
        <router-link to="loginbyverification"
          ><span class="verification-code"></span
        ></router-link>
        <router-link to="loginbywechat"
          ><span class="wechat-code"></span
        ></router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
import { request } from "@/network";
import { getMemberId } from '@/assets/public/utils/token';
import {
  getnoreadmessage,
  // getMemberId
} from "@/assets/public/utils/token";
export default {
  name: "loginRightVerification",
  data() {
    return {
      keepWake: false,
      verLoginForm: {
        phone: "",
        code: "",
      },
      verLoginFormRules: {
        phone: [
          {
            required: true,
            pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
            message: "请输入正确的11位手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      button_label: "获取验证码",
      disabled: false,
    };
  },
  methods: {
    restempty(fval, value) {
      this[fval][value] = this[fval][value].replace(/[, ]/g, "");
    },
    getVerfiCode() {
      // 阻止多次出现消息提示弹窗
      this.$message.closeAll();
      this.$refs.verLoginFormRef.validateField("phone", async (validMes) => {
        if (validMes) return this.$message.error(validMes);
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/sendMessage",
          params: {
            mobile: this.verLoginForm.phone,
          },
        });
        if (res.code !== 200) return this.$message.error(res.message);
        if (res.code === 200) {
          this.$message.success("验证码已发送，请注意查收");
          this.countdown();
        }
      });
    },
    goBindWX() {
      let str = "";
      Object.values(this.$route.query).forEach((v) => {
        str += v;
      });
      this.$refs.verLoginFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await request({
            method: "POST",
            url: "/pcp/bindWX",
            data: {
              mobile: this.verLoginForm.phone,
              code: this.verLoginForm.code,
              openId: str,
              sysType: this.$sysType,
            },
          });
          if (res.code === 1003)
            return this.$router.push({
              path: "/login/loginPhoneBind",
              query: res.data,
            });
          if (res.code === 501) return this.$message.error(res.message);
          if (res.code !== 200) return this.$message.error("绑定失败");
          this.$message.success("绑定成功");
          this.$store.commit("login/setUser", [res.data, true]);
          this.$router.push("/index");
          this.getLoginInfo();
        }
      });
    },
    countdown() {
      let time = 60;
      const timer = setInterval(() => {
        if (time === 0 || this.verLoginForm.phone.length < 11) {
          clearInterval(timer);
          this.disabled = false;
          this.button_label = "获取验证码";
        } else {
          this.button_label = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户信息失败");
      getnoreadmessage(request, res.data.id).then((resMessage) => {
        localStorage.setItem("notRead", resMessage);
        this.$store.commit("personal/setNotRead", resMessage);
        this.$store.commit("personal/setImgUrl", res.data.avatar);
      });
    },
  },
};
</script>

<style scoped lang="less">
/*总体样式*/
.verification {
  // height: 426px;
  width: 320px;
  margin-left: 139px;
  padding: 40px 0;
}
/*右侧头部样式*/
.login-right-top {
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between;
  border-bottom: 2px solid #000000;
}
.login-right-top span {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.login-right-top a {
  align-self: flex-end;
  font-size: 14px;
  color: #156ED0;
  cursor: pointer;
}
/*右侧表单样式*/
.login-form {
  margin-top: 35px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.el-input {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.el-form-item:nth-child(2) .el-input {
  width: 210px;
  margin-right: 10px;
}
.el-form-item:nth-child(2) .el-button {
  width: 100px;
  height: 50px;
  padding: 12px 0;
  font-size: 14px;
  border: 1px solid #156ED0;
  color: #156ED0;
}
.el-form-item:nth-child(2) {
  position: relative;
}
.el-form-item:nth-child(2) .el-button {
  margin-left: 0;
}
/*由于“获取验证码”按钮在强制设置高度之后样式会错位（在此处就是强行往下走了1.5px），所以得处理*/
.get-verfi {
  position: absolute;
}
.el-form-item:nth-child(n-1) {
  margin-bottom: 20px;
}
.el-form-item:last-child {
  margin-bottom: 0;
}
.login-form /deep/.el-input > input {
  height: 50px;
  padding-left: 12px;
  font-size: 16px;
  color: #8d8d8d;
}
.submit-btn {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #156ED0;
}
#checkbox-form {
  position: relative;
}
#checkbox-form .register {
  position: absolute;
  right: 0;
  top: 50%;
  color: #156ED0;
  cursor: pointer;
}
/*消除checkbox高度*/
#checkbox-form /deep/ .el-form-item__content {
  line-height: 0;
}
/*右侧表单尾部区域 */
.login-right-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  height: 24px;
  width: 100%;
}
.login-right-footer .left {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.login-right-footer .left .verification-code {
  display: block;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: url("~@/assets/pc/images/login/login-verification-code.png");
  cursor: pointer;
}
.login-right-footer .left .wechat-code {
  display: block;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background: url("~@/assets/pc/images/login/login-wechat-code.png");
  cursor: pointer;
}
</style>
